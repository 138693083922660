body {
  background: #eff1f4;
}

.background {
  background: #eff1f4;
}

.layoutmain {
  padding-right: calc(17px - 100vw + 100%);
  padding-left: 17px;
  margin: 0 auto;
  width: 100%;
}

.center {
  margin: 0 auto;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.noborder {
  border: none !important;
}

.unclickable {
  cursor: default !important;
}

.notransform {
  transform: none !important;
}

.nounderscore,
.nounderscore:active,
.nounderscore:hover,
.nounderscore:focus {
  text-decoration: none !important;
}

.mainCard {
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 4px;
  background-color: #fff;
  padding: 30px;
  margin-bottom: 12px;
}

.nomenuborder,
.nomenuborder:active,
.nomenuborder:hover,
.nomenuborder:focus {
  border: none !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.overviewRow {
  height: 80px;
}

.hoverborder {
  border: 1px solid transparent;
  padding: 4px;
}
.hoverborder:hover {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 4px;
}

.hovergrey:hover {
  background-color: #eff1f4;
  border-radius: 2px;
}

.scroller {
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

.white {
  background-color: #fff;
}

.ant-form-item-label {
  line-height: 20px;
}

.rowactive {
  background-color: #f1f5fb;
}

.tablepagination {
  text-align: right;
  margin: 20px;
}

.fadetransition {
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}

/* Quill adjustment (adjusted along with the theme SNOW) */
.ql-editor {
  font-family: "Open Sans";
  min-height: 200px !important;
}

.ql-snow {
  border: none !important;
  color: black;
}

.ql-editor p {
  padding-top: 12px;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3 {
  padding-top: 16px;
}

.ql-editor :first-child {
  padding-top: 0px;
}

.dont-break-out {
  /*magic stuff from the webs*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.site-collapse-custom-collapse {
  /*twisting antd collapse into a menu*/
  background: #fff;
  padding: 0px;
  overflow: hidden;
}
.site-collapse-custom-panel {
  /*twisting antd collapse into a menu*/
  background: #fff;
  overflow: hidden;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-word;
  white-space: normal;
}

.fadeOut {
  opacity: 0;
  height: 0px;
  transition: height 0.3s, opacity 0.2s;
  overflow: hidden;
}
.fadeIn {
  opacity: 1;
  height: 100px;
  transition: height 0.3s, opacity 0.2s;
}

.rotateicon {
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  -ms-transition: -ms-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.movecursor {
  cursor: move;
}

/*card container used for antd tabs in overview */
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -18px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  border-color: lightgrey;
  background: rgb(247, 247, 247);
}

.card-container > .ant-tabs-card .ant-tabs-tab-active {
  border-color: lightgrey;
  background: #fff;
}
.card-container > .ant-tabs-card .ant-tabs-tab:hover {
  background: rgb(232, 238, 238);
}
